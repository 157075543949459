import anime from 'animejs/lib/anime.es.js';
import React, { ReactDOM } from 'react';
import './App.css';
import StarrySky from './starnight';
import { Lrc } from 'react-lrc';
import WE from './we.png';

function Fade() {
  var t1 = document.getElementById('t1');
  var t2 = document.getElementById('t2');
  var t3 = document.getElementById('t3');
  var t4 = document.getElementById('t4');
  var t5 = document.getElementById('t5');

  var TVid = document.getElementById('TasjiaVid');

  TVid.setAttribute("style", "visibility:visible;height:30rem;width:30rem;padding-bottom:10rem;");
  // TVid.play();
  
  setTimeout(function(){
    t1.setAttribute('style', 'visibility:visible');
    t2.setAttribute('style', 'visibility:hidden');
    t3.setAttribute('style', 'visibility:hidden');
    t4.setAttribute('style', 'visibility:hidden');
    t5.setAttribute('style', 'visibility:hidden');

    // var TasjiaName = document.getElementsByClassName("TasjiaName")[0];
    // TasjiaName.setAttribute("style", "margin-top:15%;");

    var audio = document.getElementById('audio');

    audio.play();

  }, 2000);
}

function addTexts() {
  var h = document.getElementById('hrt');
  var h2 = document.getElementById('hrt2');

  h.setAttribute('id', 'fade-out');
  h2.setAttribute('id', 'fade-out');

  var Application = document.getElementsByClassName("chat")[0];

  setTimeout(function() {
    Application.setAttribute("style", "visibility:visible");
    Application.innerHTML = '<div class="chat__message chat__message_C" style="--delay: 20s;">*Seven Months Later*</div></div></div><div class="chat__message chat__message_B" style="--delay: 18s;"> <div class="chat__content"> <p>I love you and please be safe.</p> </div> </div> <div class="chat__message chat__message_A" style="--delay: 13s;"> <div class="chat__content"> <p>I have to leave now, I will talk to you later.</p> </div> </div> <div class="chat__message chat__message_A" style="--delay: 10s;"> <div class="chat__content"> <p>Really? I would love to...</p> </div> </div> <div class="chat__message chat__message_B" style="--delay: 6s;"> <div class="chat__content"> <p>Hey I know this is bad timing but <br /> do you want to be my girlfriend?</p> </div> </div> <div class="chat__message chat__message_A" style="--delay: 1s;"> <div class="chat__content"><p>I\'m going out to a party. <br />I will text you when I can.</p></div></div><div class="chat__message chat__message_C" style="--delay: 0.1s;">05/28/2022</div></div>';
  }, 3000);

  setTimeout(function() {
    Application.setAttribute("style", "visibility:hidden");
    Application.innerHTML = '';
  },25000);

  setTimeout(function() {
    Fade();
  },26500);
}

function App() {
  return (
    <div className="App">
      <StarrySky />
      <header className="App-header">
        <h1 className="start" id="hrt2" onClick={addTexts}>Start</h1>
        <svg className="heart" id="hrt" viewBox="0 0 32 29.6">
          <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
          c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
        </svg>
        {/* <video src={"https://cdn.discordapp.com/attachments/734235036877979684/1024757509019414529/video.MOV"} style={{visibility: 'hidden'}} id='TasjiaVid'></video> */}
        <img src={WE} style={{visibility: 'hidden'}} id='TasjiaVid'/>
        <p className="rainbow_text_animated TasjiaName" id='t1'>Anastasjia ♥ Gabrielle ♥ Scarlet ♥ Delbridge</p>
        <p className="verticle-text" id='t2'>dorable</p>
        <p className="verticle-text2" id='t3'>orgeous</p>
        <p className="verticle-text3" id='t4'>weet</p>
        <p className="verticle-text4" id='t5'>reamy</p>
        <div id="lyricer">
        </div>
        <section className="chat" style={{visibility: 'hidden'}}></section>
      </header>
    </div>
  );
}

export default App;
